<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button class="fc-0075F6" slot="append" type="primary" icon="el-icon-search" @click="submitSearch">
            </el-button>
          </el-input>
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
      <div class="retrieveDiv flex flex-ac">
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">用户名称：</span>
          <el-input class="w-250" v-model="retrieveForm.name"></el-input>
        </div>
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">用户手机号：</span>
          <el-input class="w-250" v-model="retrieveForm.name"></el-input>
        </div>
        <div label="">
          <el-button type="primary" @click="submitSearch">查询</el-button>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
        <template v-slot:image="e">
          <img :src="e.row.image" alt="" class="tableImg" />
        </template>
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
    </TableHeightFull>
    <zero-activity-details ref="zero-activity-details"></zero-activity-details>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
export default {
  name: 'zeroActivity',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "zero-activity-details": (resolve) => require(["./zeroActivityDetails"], resolve)
  },
  data () {
    return {
      retrieveForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
      },
      tabLoading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', index: this.indexMethod },
        { columnType: 'custom', label: '封面图', prop: 'image', align: 'left', 'show-overflow-tooltip': true },
        { label: '名称', prop: 'name', align: 'left', 'show-overflow-tooltip': true },
        { label: '时间', prop: 'date', align: 'left', width: '300' },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '150',
          buttons: [
            { type: 'text', text: '删除', fn: this.handleEditRow, },
          ],
        },
      ],
      tableData: [],
      //弹框
      dialogFormVisible: false,
      dialogForm: {
        region: '',
        num: 0,
        dataArray: [],
      }
    }
  },
  mounted () {
    console.log(window.Glob)
    this.tableData = [{
      date: '2016-05-02',
      name: '王小虎',
      province: '上海',
      city: '普陀区',
      address: '上海市普陀区金沙江路 1518 弄',
      zip: 200333,
      image: 'https://qn.hzkuqu.com/wechat%2Fces%2F%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220704163156.png',
    }, {
      date: '2016-05-02',
      name: '王小虎',
      province: '上海',
      city: '普陀区',
      address: '上海市普陀区金沙江路 1518 弄',
      zip: 200333,
      image: 'https://qn.hzkuqu.com/wechat%2Fces%2F%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220704163156.png',
    }, {
      date: '2016-05-02',
      name: '王小虎',
      province: '上海',
      city: '普陀区',
      address: '上海市普陀区金沙江路 1518 弄',
      zip: 200333,
      image: 'https://qn.hzkuqu.com/wechat%2Fces%2F%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220704163156.png',
    }]
  },
  methods: {
    //查询
    submitSearch () {

    },
    //新增列表
    addList () {
      this.$refs['zero-activity-details'].init('add')
    },
    //导出
    exportExcel () {

    },
    //获取表格数据
    getTableData () {

    },
    //跳转详情
    headerRowClick (row) {
      console.log('跳转详情')
      console.log(row)
      this.$refs['zero-activity-details'].init('user', row)
    },
    handleSelectionChange () {

    },
  },
}
</script>

<style lang="less" scoped>
</style>